* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 16px;
}

@font-face {
  font-family: "creato_displayblack";
  src: url("./assets/fonts/creatodisplay-black-webfont.woff2") format("woff2"),
    url("./assets/fonts/creatodisplay-black-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato_displayblack_italic";
  src: url("./assets/fonts/creatodisplay-blackitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/creatodisplay-blackitalic-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato display bold";
  src: url("./assets/fonts/creatodisplay-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/creatodisplay-bold-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato_displaybold_italic";
  src: url("./assets/fonts/creatodisplay-bolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/creatodisplay-bolditalic-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato_displayextrabold";
  src: url("./assets/fonts/creatodisplay-extrabold-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/creatodisplay-extrabold-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato_displayXBdIt";
  src: url("./assets/fonts/creatodisplay-extrabolditalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/creatodisplay-extrabolditalic-webfont.woff")
      format("woff");
}

@font-face {
  font-family: "creato display light";
  src: url("./assets/fonts/creatodisplay-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/creatodisplay-light-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato_displaylight_italic";
  src: url("./assets/fonts/creatodisplay-lightitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/creatodisplay-lightitalic-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato display medium";
  src: url("./assets/fonts/creatodisplay-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/creatodisplay-medium-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato_displaymedium_italic";
  src: url("./assets/fonts/creatodisplay-mediumitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/creatodisplay-mediumitalic-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato display regular";
  src: url("./assets/fonts/creatodisplay-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/creatodisplay-regular-webfont.woff") format("woff");
}

@font-face {
  font-family: "creato_displayitalic";
  src: url("./assets/fonts/creatodisplay-regularitalic-webfont.woff2")
      format("woff2"),
    url("./assets/fonts/creatodisplay-regularitalic-webfont.woff")
      format("woff");
}

a {
  text-decoration: none;
}

#portal {
  position: relative;
  z-index: 99999999999999;
}

.Toastify__toast-container {
  z-index: 9999999999999999999;
}

.svg-school-icon {
  stroke: #212121;
}
.svg-school-icon:hover {
  stroke: "#5D5D5D";
}
